import classnames from 'classnames';

interface TailwindClassnames {
  /* Layout */
  objectFit?: 'object-contain' | 'object-cover' | 'object-fit' | 'object-none' | 'object-scale-down'
  objectPosition?: 'object-bottom' | 'object-center' | 'object-left' | 'object-left-bottom' | 'object-left-top' | 'object-right' | 'object-right-bottom' | 'object-right-top' | 'object-top'
  /* Positioning */
  position?: string
  itemAlignment?: string
  /* Display & Box Model */
  alignment?: string
  border?: string
  borderRadius?: string
  container?: boolean
  display?: string
  grid?: string
  height?: string
  margin?: string
  overflow?: string
  padding?: string
  shadow?: string
  width?: string
  zIndex?: string
  /* Color */
  dynamicBackground?: string
  background?: string
  colour?: string
  dynamicColour?: string
  /* Text */
  letterSpacing?: string
  lineHeight?: string
  fontSize?: string
  fontWeight?: string0
  fontFamily?: string
  textAlign?: string
  uppercase?: boolean
  /* Image */
  aspectRatio?: string
  /* Other */
}

const tailwindClassnames = (classes: TailwindClassnames): any => {

  const tailwindClassList: string[] = [];

  // console.log('tailwindClassnames', Object.entries(classes))
  // Handle Background CSS
  classes && Object.keys(classes).map((currentClass: string) => {
    // console.log('classname', classname)
    // console.log('classname value', classnames[classname])
    /* Layout */
    currentClass === 'objectFit' && classes[currentClass] !== undefined && tailwindClassList.push(`${<string>classes[currentClass]}`)
    currentClass === 'objectPosition' && classes[currentClass] !== undefined && tailwindClassList.push(`${<string>classes[currentClass]}`)
    /* Positioning */
    currentClass === 'position' && classes[currentClass] !== undefined && tailwindClassList.push(`${<string>classes[currentClass]}`)
    currentClass === 'alignment' && classes[currentClass] !== undefined && tailwindClassList.push(`${<string>classes[currentClass]}`)
    currentClass === 'itemAlignment' && classes[currentClass] !== undefined && tailwindClassList.push(`${<string>classes[currentClass]}`)
    /* Display & Box Model */
    currentClass === 'container' && classes[currentClass] === true && tailwindClassList.push(`container`)
    currentClass === 'display' && classes[currentClass] !== undefined && tailwindClassList.push(`${<string>classes[currentClass]}`)
    currentClass === 'grid' && classes[currentClass] !== undefined && tailwindClassList.push(`${<string>classes[currentClass]}`)
    currentClass === 'border' && classes[currentClass] !== undefined && tailwindClassList.push(`${<string>classes[currentClass]}`)
    currentClass === 'height' && classes[currentClass] !== undefined && tailwindClassList.push(`${<string>classes[currentClass]}`)
    currentClass === 'borderRadius' && classes[currentClass] !== undefined && tailwindClassList.push(`${<string>classes[currentClass]}`)
    currentClass === 'margin' && classes[currentClass] !== undefined && tailwindClassList.push(`${<string>classes[currentClass]}`)
    currentClass === 'overflow' && classes[currentClass] !== undefined && tailwindClassList.push(`overflow-${<string>classes[currentClass]}`)
    currentClass === 'padding' && classes[currentClass] !== undefined && tailwindClassList.push(`${<string>classes[currentClass]}`)
    currentClass === 'shadow' && classes[currentClass] !== undefined && tailwindClassList.push(`${<string>classes[currentClass]}`)
    currentClass === 'width' && classes[currentClass] !== undefined && tailwindClassList.push(`${<string>classes[currentClass]}`)
    currentClass === 'zIndex' && classes[currentClass] !== undefined && tailwindClassList.push(`${<string>classes[currentClass]}`)
    /* Color */
    currentClass === 'dynamicBackground' && classes[currentClass] !== undefined && tailwindClassList.push(`bg-[${<string>classes[currentClass]}]`)
    currentClass === 'background' && classes[currentClass] !== undefined && tailwindClassList.push(`${<string>classes[currentClass]}`)
    currentClass === 'colour' && classes[currentClass] !== undefined && tailwindClassList.push(`${<string>classes[currentClass]}`)
    currentClass === 'dynamicColour' && classes[currentClass] !== undefined && tailwindClassList.push(`text-[${<string>classes[currentClass]}]`)
    /* Text */
    currentClass === 'fontFamily' && classes[currentClass] !== undefined && tailwindClassList.push(`${<string>classes[currentClass]}`)
    currentClass === 'fontSize' && classes[currentClass] !== undefined && tailwindClassList.push(`${<string>classes[currentClass]}`)
    currentClass === 'fontWeight' && classes[currentClass] !== undefined && tailwindClassList.push(`font-${<string>classes[currentClass]}`)
    currentClass === 'letterSpacing' && classes[currentClass] !== undefined && tailwindClassList.push(`${<string>classes[currentClass]}`)
    currentClass === 'lineHeight' && classes[currentClass] !== undefined && tailwindClassList.push(`${<string>classes[currentClass]}`)
    currentClass === 'textAlign' && classes[currentClass] !== undefined && tailwindClassList.push(`text-${<string>classes[currentClass]}`)
    currentClass === 'uppercase' && classes[currentClass] === true && tailwindClassList.push(`uppercase`)
    /* Image */
    currentClass === 'aspectRatio' && classes[currentClass] !== undefined && tailwindClassList.push(`${<string>classes[currentClass]}`)
  })

  console.log(classnames(tailwindClassList))
  return classnames(tailwindClassList);

}

export default tailwindClassnames
